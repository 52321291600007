import React, { useEffect, useState } from "react";

// third party
import { useScrollYPosition } from "react-use-scroll-position";

// components
import FooterLogo from "./FooterLogo";
import FooterInfo from "./FooterInfo";

// css styles
import "./FooterOverlay.css";
import FooterLinks from "./FooterLinks";
import FooterHelpLinks from "./FooterHelpLinks";
import BackToTop from "./BackToTop";
import AllRightsReserved from "./AllRightsReserved";

const FooterOverlay = ({
	ourwork_category,
	linkWebsite,
	homeFooter,
	registrationMarketer,
	logoFooter,
	termsPages,
}) => {
	const scrolly = useScrollYPosition();
	const [showup, setShowup] = useState(false);

	useEffect(() => {
		if (scrolly >= 500) {
			setShowup(true);
		} else {
			setShowup(false);
		}
	}, [scrolly]);

	// handle open page in top position
	let goUpWindow = () => {
		window.scroll(0, 0);
	};

	return (
		<>
			<footer className='main-footer'>
				<div className='all'>
					<div className='container flex-column flex-lg-row  align-items-center align-items-lg-start '>
						{/* footer logo */}
						<FooterLogo logoFooter={logoFooter} linkWebsite={linkWebsite} />

						<nav className='box-right'>
							<ul
								className={`${
									ourwork_category?.length === 0
										? " justify-content-center"
										: ""
								}`}>
								{/* Footer Info */}

								<FooterInfo
									title='معلومات'
									links={homeFooter}
									goUpWindow={goUpWindow}
								/>

								{/* Footer Links */}
								<FooterLinks registrationMarketer={registrationMarketer} />

								{/* Footer Help Links */}
								<FooterHelpLinks goUpWindow={goUpWindow} />

								<FooterInfo
									title='الشروط والأحكام'
									links={termsPages}
									goUpWindow={goUpWindow}
								/>
							</ul>
						</nav>
					</div>

					<AllRightsReserved />
				</div>
				<BackToTop showup={showup} />
			</footer>
		</>
	);
};

export default FooterOverlay;

import React from "react";

// Third party
import { toast } from "react-toastify";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

// RTK Query
import { useGetSubscribePackagesQuery } from "../../RTK/Api/subscribePackagesApi";

const HeroBanners = ({ homePageData, navigate }) => {
	//
	const { data: packages } = useGetSubscribePackagesQuery();

	const businessStorePack =
		packages && Array.isArray(packages)
			? packages?.find(
					(pack) =>
						pack?.id === 2 ||
						pack?.name === "متجر الاعمال" ||
						pack?.name === "متجر الأعمال" ||
						pack?.name === "باقة متجر الأعمال"
			  )
			: null;

	const myBrandStorePack =
		packages && Array.isArray(packages)
			? packages?.find((pack) => pack?.id === 1 || pack?.name === "باقة المتجر")
			: null;

	// to disabled right click
	const handleContextMenu = (event) => {
		event.preventDefault(); // Prevent the default right-click behavior

		toast.warning("لا يمكن استخدام خاصية الضغط بالزر الايمن مع هذه القسم", {
			theme: "light",
		});
	};

	// navigate to auth/login by default
	const navigateToMerchantRegister = () => {
		window.location.href = "https://store.modelss.co/compare-packages";
	};

	const slides = [
		homePageData?.slider1,
		homePageData?.slider2,
		homePageData?.slider3,
	].filter(Boolean);

	const isLoopEnabled = slides.length >= 2; // Enable loop only if enough slides

	return (
		<div className='hero' onContextMenu={handleContextMenu}>
			<Swiper
				effect='fade'
				loop={isLoopEnabled} // Dynamically enable loop
				autoplay={{
					delay: 6000,
					disableOnInteraction: false,
				}}
				pagination={{
					dynamicBullets: true,
					clickable: true,
				}}
				modules={[Pagination, EffectFade, Autoplay]}
				className='my_hero_swiper'>
				{slides.map((slide, index) => (
					<SwiperSlide key={index} className='my_hero_swiper_slide'>
						<div
							className='swiper_image'
							style={{
								backgroundImage: `url(${slide})`,
								cursor: index > 0 ? "pointer" : "default",
							}}
							onClick={() => {
								if (index === 1) {
									navigate(`/business-store/${businessStorePack?.id}`);
								} else if (index === 2) {
									navigate(`/package-info/${myBrandStorePack?.id}`);
								}
							}}>
							{index === 0 && (
								<div className='content'>
									<h1> ابدأ متجرك الإلكتروني الآن</h1>
									<p>حول فكرتك إلى متجر إلكتروني، استفد من خدماتنا الشاملة</p>
									<button
										className='bt-main'
										onClick={navigateToMerchantRegister}>
										أنشئ متجرك الآن
									</button>
								</div>
							)}
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default HeroBanners;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SearchInput from "../SearchInput/SearchInput";

import "./Header.css";

// Icons
import { FiMenu } from "react-icons/fi";
import { Logo } from "../../assets/Icons";
import { IoMdClose } from "react-icons/io";

// Context
import AppContext from "../../Context/AppContext";

const Header = ({ homeFooter }) => {
	const postsContext = useContext(AppContext);
	const { setPostCategoryId } = postsContext;

	const navigate = useNavigate();
	let location = useLocation();
	let [activeLink, setActiveLink] = useState(`التسجيل`);
	let [showForm, setShowForm] = useState(true);
	const [isShownMenu, setIsShownMenu] = useState(true);

	if (location.pathname === "/packagePage") {
		document.querySelector("body").style.overflow = "hidden";
	} else {
		document.querySelector("body").style.overflow = "auto";
	}

	useEffect(() => {
		if (
			document
				.querySelector(".header .navbar .navbar-collapse")
				.classList.contains("show")
		) {
			document.querySelector(".header .navbar .show").classList.remove("show");
			document
				.querySelector(".header .navbar button")
				.classList.add("collapsed");
		}
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname === "/") {
			setActiveLink("التسجيل");
		}
		if (location.pathname === "/start") {
			setActiveLink("كيف أبدأ");
		}
		if (location.pathname === "/blog") {
			setActiveLink("المدونة");
		}

		if (location.pathname === "/pages/367") {
			setActiveLink("الدورات التدريبية");
		}
		if (location.pathname === "/services") {
			setActiveLink("خدماتنا");
		}

		if (location.pathname === "/packages") {
			setActiveLink("الباقات");
		}
	}, [location.pathname]);

	/* handle sow/hidden the search input */
	useEffect(() => {
		if (location.pathname === "/" || location.pathname === "/start") {
			setShowForm(true);
		} else {
			setShowForm(false);
		}
	}, [location.pathname]);

	// navigate to auth/login by default
	const navigateToMerchantRegister = () => {
		window.location.href = "https://store.modelss.co/auth/login";
	};

	// We use this filter to get all pages about category الدورات التدريبية
	const trainingCoursesPage = homeFooter?.find(
		(page) =>
			page?.pageCategory?.some((category) => category?.name === "معلومات") &&
			page?.title === "الدورات التدريبية"
	);

	return (
		<>
			<div className='header'>
				<div className='container'>
					<Navbar expand='lg'>
						<Navbar.Brand>
							<Logo
								onClick={() => navigate("/")}
								style={{ cursor: "pointer" }}
							/>
						</Navbar.Brand>

						<Navbar.Toggle
							aria-controls='navbarScroll'
							onClick={() => setIsShownMenu(false)}>
							<FiMenu />
						</Navbar.Toggle>
						<Navbar.Collapse id='navbarScroll' className=''>
							<Form className={showForm === true ? "show" : ""}>
								<SearchInput />
							</Form>
							<Nav className='me-auto my-2 my-lg-0' navbarScroll>
								<Link to={"/"} onClick={() => setActiveLink(`التسجيل`)}>
									الرئيسية
								</Link>
								<Link
									to={"/services"}
									className={activeLink === "خدماتنا" ? "active" : ""}
									onClick={() => setActiveLink(`خدماتنا`)}>
									خدماتنا
								</Link>
								{trainingCoursesPage && (
									<Link
										to={`/page/${trainingCoursesPage?.id}/${encodeURIComponent(
											trainingCoursesPage?.title
												.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
												.toLowerCase()
										)}`}
										className={
											activeLink === "الدورات التدريبية" ? "active" : ""
										}
										onClick={() => {
											setActiveLink(`الدورات التدريبية`);
										}}>
										الدورات التدريبية
									</Link>
								)}

								<Link
									to={"/packages"}
									className={activeLink === "الباقات" ? "active" : ""}
									onClick={() => setActiveLink(`الباقات`)}>
									الباقات
								</Link>

								<Link
									to={"/start"}
									className={activeLink === "كيف أبدأ" ? "active" : ""}
									onClick={() => setActiveLink(`كيف أبدأ`)}>
									كيف أبدأ
								</Link>

								<Link
									to={"/blog"}
									className={activeLink === "مقالات" ? "active" : ""}
									onClick={() => {
										setPostCategoryId(0);
										setActiveLink(`مقالات`);
									}}>
									مقالات
								</Link>

								<Link
									className={
										location.pathname === "/" || activeLink === "التسجيل"
											? "active"
											: ""
									}
									onClick={() => {
										setActiveLink(`التسجيل`);
										navigateToMerchantRegister();
									}}>
									التسجيل
								</Link>
							</Nav>
							<div
								className={`close-nav-bar ${isShownMenu ? "" : "show"}`}></div>
							<div className='close-nav-bar-icon'>
								<Navbar.Toggle
									aria-controls='navbarScroll'
									onClick={() => setIsShownMenu(false)}>
									<IoMdClose />
								</Navbar.Toggle>
							</div>
						</Navbar.Collapse>
					</Navbar>
				</div>
			</div>
		</>
	);
};

export default Header;

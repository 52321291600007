import React from "react";
import { Link } from "react-router-dom";

const FooterLinks = ({ title, registrationMarketer }) => {
	// navigate to auth/merchant
	const navigateToMerchantRegister = () => {
		window.location.href = "https://store.modelss.co/auth/merchant";
	};

	// navigate to auth/delegate
	const navigateToDelegateRegister = () => {
		window.location.href = "https://store.modelss.co/auth/delegate";
	};

	return (
		<li className='text-center text-lg-end '>
			<h4 className=''>حسابي</h4>
			<ul className='text-center text-lg-end '>
				<li>
					<Link
						onClick={navigateToMerchantRegister}
						className='main-footer-link'>
						التسجيل كتاجر
					</Link>
				</li>

				{registrationMarketer === "active" && (
					<li>
						<Link
							onClick={navigateToDelegateRegister}
							className='main-footer-link'>
							التسجيل كمندوب
						</Link>
					</li>
				)}
			</ul>
		</li>
	);
};

export default FooterLinks;

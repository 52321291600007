import React from "react";
import { StartBox } from "../components/";
import { Helmet } from "react-helmet-async";

const Start = () => {
	return (
		<>
			<Helmet>
				<title>المنصة | كيف أبدأ</title>
				<meta
					name='description'
					content='ابدأ مع المنصة بوابتك للتجارة الإلكترونية'
				/>
			</Helmet>
			<StartBox />
		</>
	);
};

export default Start;

import React from "react";

import "./WhatWeOffer.css";

import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import ServicesBox from "../../Pages/Services/ServicesBox";
import { useServicesContext } from "../../Context/ServicesContext";

const WhatWeOffer = () => {
	const navigate = useNavigate();

	const { servicesData } = useServicesContext();

	const servicesLength = servicesData?.Services?.length;

	return (
		<section className='what-we-offer-section '>
			<section className=''>
				<section className='what-we-offer-box-content row  align-items-center'>
					{/* text */}
					<div className='col-lg-12 services-head-section mb-4 text-center'>
						<h2>ماذا نقدم لك؟</h2>

						<p> المنصة تقدم لك مجموعة من الخدمات لنجاح مشروعك التجاري</p>
					</div>

					<div className='services'>
						<div className='container '>
							<ServicesBox servicesData={servicesData?.Services?.slice(0, 8)} />
						</div>
					</div>

					{servicesLength > 8 && (
						<div className='col-12'>
							<bdi
								className='mt-4 more-service-btn'
								onClick={() => {
									navigate("/services");
								}}>
								المزيد من الخدمات
								<HiOutlineArrowNarrowLeft />
							</bdi>
						</div>
					)}
				</section>
			</section>
		</section>
	);
};

export default WhatWeOffer;

import React from "react";

const AllRightsReserved = () => {
	return (
		<div className='copyright-box container'>
			<hr />
			<h5>
				جميع الحقوق محفوظة <span> للمنصة </span>2023 برمجة وتطوير{" "}
				<span>
					<a href='https://faz-it.net'>شبكة فاز</a>
				</span>
			</h5>
		</div>
	);
};

export default AllRightsReserved;

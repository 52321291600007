import React, { createContext, useContext, useState } from "react";
import { useGetServicesQuery } from "../RTK/Api/servicesApi";

const ServicesContext = createContext();

const ServicesProvider = ({ children }) => {
	const [selectedId, setSelectedId] = useState(0);
	const [openOrderServiceModal, setOpeOrderServiceModal] = useState(false);
	const [pageNumber, setPageNumber] = useState(16);
	const [currentPage, setCurrentPage] = useState(1);

	const { data: servicesData, isLoading } = useGetServicesQuery({
		pageNumber,
		currentPage,
	});

	return (
		<ServicesContext.Provider
			value={{
				servicesData,
				isLoading,
				selectedId,
				setSelectedId,
				openOrderServiceModal,
				setOpeOrderServiceModal,
				pageNumber,
				setPageNumber,
				currentPage,
				setCurrentPage,
			}}>
			{children}
		</ServicesContext.Provider>
	);
};

const useServicesContext = () => {
	const context = useContext(ServicesContext);
	if (context === undefined) {
		throw new Error(
			"useServicesContext must be used within a ServicesProvider"
		);
	}
	return context;
};

export { useServicesContext, ServicesProvider };

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../Context/AppContext";
const InformationLinks = ({ el, goUpWindow }) => {
	const navigate = useNavigate();
	return (
		<li
			key={el?.id}
			className={
				el?.title === "الدورات التدريبية" || el?.title === "من نحن"
					? "d-none"
					: "main-footer-link"
			}
			onClick={() => {
				navigate(
					`/page/${el.id}/${encodeURIComponent(
						el?.title.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-").toLowerCase()
					)}`
				);

				goUpWindow();
			}}>
			{el?.title}
		</li>
	);
};

const OurWorksLinks = ({ el, goUpWindow }) => {
	const navigate = useNavigate();
	const postsContext = useContext(AppContext);
	const { setPostCategoryId } = postsContext;

	return (
		<li
			key={el?.id}
			className={
				el?.name === "اعمالنا في التصميم" ||
				el?.name === "اعمالنا في ادارة المتاجر و التسويق"
					? "main-footer-link "
					: "d-none"
			}
			onClick={() => {
				if (el?.name === "اعمالنا في التصميم") {
					navigate(`/our-works`);
					setPostCategoryId(el.id);
				} else if (el?.name === "اعمالنا في ادارة المتاجر و التسويق") {
					navigate(`/our-works`);
					setPostCategoryId(el.id);
				}
				goUpWindow();
			}}>
			{el?.name}
		</li>
	);
};

const FooterInfo = ({ title, links, goUpWindow }) => {
	return (
		<li className='text-center text-lg-end'>
			<h4 className=''>{title}</h4>
			<ul className='text-center'>
				{links?.map((el) => (
					<InformationLinks el={el} goUpWindow={goUpWindow} key={el?.id} />
				))}
			</ul>
		</li>
	);
};

export default FooterInfo;

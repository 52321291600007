import React from "react";

// third party
import DOMPurify from "dompurify";

//icons
import { Star } from "../../assets/Icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useServicesContext } from "../../Context/ServicesContext";
import OrderService from "./OrderService";

const ServicesBox = ({ servicesData }) => {
	const {
		selectedId,
		setSelectedId,
		setOpeOrderServiceModal,
		openOrderServiceModal,
	} = useServicesContext();
	const handleOpenOrderServiceModal = () => {
		setOpeOrderServiceModal(true);
	};

	const handleServiceClick = (serviceId) => {
		setSelectedId(serviceId === selectedId ? 0 : serviceId);
	};
	return (
		<>
			<div className='boxes' data-aos-once='true' data-aos='fade-up'>
				{servicesData?.map((service) => (
					<div
						key={service?.id}
						role='button'
						tabIndex={0}
						onClick={() => handleServiceClick(service?.id)}
						onKeyDown={(e) =>
							e.key === "Enter" && handleServiceClick(service?.id)
						}
						className={`${service?.id === selectedId ? "selected" : ""} box-${
							service?.id
						} box`}
						aria-expanded={service?.id === selectedId}>
						<div className='star-icon'>
							<Star />
						</div>
						<p className='service-title'>{service?.name}</p>
						<div className='arrow'>
							<KeyboardArrowDownIcon />
						</div>

						{service?.id === selectedId && (
							<div className='detalis'>
								<div
									className='desc'
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(service?.description),
									}}
								/>

								<div className='services-btn-container d-flex justify-content-between align-items-center gap-2 mb-3 px-3'>
									<button
										onClick={handleOpenOrderServiceModal}
										className='order-service-btn d-flex justify-content-center align-items-center'
										aria-label='طلب الخدمة'>
										طلب الخدمة
									</button>
									<div className='service-price d-flex justify-content-center align-items-center'>
										{service?.price} <span className='currency'>ر.س</span>
									</div>
								</div>
							</div>
						)}
					</div>
				))}
			</div>

			<OrderService
				id={selectedId}
				openOrderServiceModal={openOrderServiceModal}
				setOpeOrderServiceModal={setOpeOrderServiceModal}
			/>
		</>
	);
};

export default ServicesBox;
